"use client";

import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useMemo } from "react";
import slideCalculator, {
  singleSlideCalculator,
} from "@/helpers/testimonialSlideCalculator";
import cn from "clsx";
import styles from "./index.module.scss";
import Image from "next/image";
import { IModalActions, ModalTypes } from "@/types/modal";
import { ModalButton } from "@/components/atoms/ModalButton";

function TestimonialComponent({
  items,
  reverse,
}: {
  reverse?: string;
  items: Testimonial;
}) {
  return (
    <div
      className={cn(
        reverse && "flex-row-reverse",
        "border border-primary-blue rounded-2xl text-primary-blue lg:flex h-full overflow-hidden",
      )}
    >
      <div
        className={cn(
          "mx-auto overflow-hidden lg:border-r-0 lg:border-l basis-[35%]",
          styles.cardImageContainer,
        )}
      >
        {items?.attributes?.Picture && (
          <Image
            src={items?.attributes?.Picture?.source.data[0].attributes.url}
            alt={
              items?.attributes?.Picture?.source.data[0].attributes
                .alternativeText || ""
            }
            width={270}
            height={300}
            className={cn("object-cover w-full h-full", styles.cardImage)}
          />
        )}
      </div>
      <div
        className={cn(
          "lg:flex lg:flex-col lg:justify-between h-full basis-[65%]",
          styles.cardContent,
        )}
      >
        <p
          className={cn(
            "lg:text-xl font-medium font-raleway",
            styles.shortDesc,
          )}
        >
          "{items?.attributes?.ShortDesc}"
        </p>
        <div>
          <h2 className={cn("font-baskervville", styles.name)}>
            {items?.attributes?.Name}
          </h2>
          <p className={cn("font-raleway opacity-80", styles.desg)}>
            {items?.attributes?.Department}, {items?.attributes?.Designation}
          </p>
        </div>
      </div>
    </div>
  );
}

function VideoTestimonialComponone({ items }: { items: VideoTestimonials }) {
  return (
    <div className="border border-primary-blue rounded-2xl bg-blue-linear-gradient h-full  xl:overflow-hidden">
      <div className="relative h-full">
        <Image
          src={items?.attributes?.VideoEmbed.PosterImage?.data?.attributes?.url}
          alt={
            items?.attributes?.VideoEmbed.PosterImage?.data?.attributes
              ?.alternativeText || ""
          }
          fill
          className={cn(
            "object-cover w-full h-full rounded-2xl mix-blend-multiply",
            styles.videoImg
          )}
        />
        <figcaption className="absolute bottom-0 left-0 w-full">
          <div className={cn("flex items-end justify-between", styles.details)}>
            <div>
              <h2
                className={cn(
                  "[text-shadow: 0px 4.362116813659668px 4.362116813659668px rgba(0, 0, 0, 0.25)]",
                  styles.name
                )}
              >
                {items?.attributes?.Name}
              </h2>
              <p className={cn(styles.desg)}>
                {items?.attributes?.Department},{" "}
                {items?.attributes?.Designation}
              </p>
            </div>
            <ModalButton
              action={IModalActions.OpenModal}
              data={{
                modalType: ModalTypes.VideoPopup,
                modalData: {
                  url: items?.attributes?.VideoEmbed?.enabled
                    ? items?.attributes?.VideoEmbed?.URL
                    : "",
                },
              }}
            >
              {items?.attributes?.ActionIcon?.map(
                ({ alt, isMobileViewIcon, source }, i: number) =>
                  isMobileViewIcon ? (
                    <img
                      src={source?.data?.attributes?.url}
                      alt={alt || ""}
                      className="h-9 w-9 max-w-none inline-block lg:hidden cursor-pointer"
                      key={i}
                    />
                  ) : (
                    <div
                      className={`h-[61px] w-[60px] hidden lg:inline-block cursor-pointer homeSprite ${styles.studentPlayIcon}`}
                    />
                  )
              )}
            </ModalButton>
          </div>
        </figcaption>
      </div>
    </div>
  )
}

const SliderContent = ({ slide1, slide2, reverse }: any) => {
  if (!slide1 || !slide2) return null;

  return (
    <div
      className={cn(
        "flex justify-between",
        reverse && "flex-row-reverse",
        styles.testimonialBox,
      )}
    >
      <div className={cn("basis-[65%]", styles.card)}>
        <TestimonialComponent reverse={reverse} items={slide1} />
      </div>
      <div className={cn("basis-[33.5%]", styles.video)}>
        <VideoTestimonialComponone items={slide2} />
      </div>
    </div>
  );
};

const SingleSliderContent = ({ slide1, slide2, reverse }: any) => {
  if (!slide1 || !slide2) return null;

  return (
    <div
      className={cn(
        "flex justify-between gap-4",
        reverse && "flex-row-reverse",
        styles.testimonialBox,
      )}
    >
      <div className={cn("basis-1/2", styles.card)}>
        <TestimonialComponent reverse={reverse} items={slide1} />
      </div>
      <div className={cn("basis-1/2", styles.video)}>
        <TestimonialComponent items={slide2} reverse={reverse} />
      </div>
    </div>
  );
};

export function StudentsGridCarousel({
  studentsDetails,
  videoTestimonials,
  isSingleSlider = false,
}: {
  studentsDetails: Testimonial[];
  videoTestimonials: VideoTestimonials[];
  isSingleSlider?: boolean;
}) {
  const testimonials = useMemo(() => {
    if (isSingleSlider) {
      return singleSlideCalculator(studentsDetails).arrayToRender as never;
    }
    return slideCalculator(studentsDetails, videoTestimonials)
      .arrayToRender as never;
  }, []);

  return (
    <div className={styles.root}>
      <Swiper
        modules={[Pagination, Autoplay]}
        spaceBetween={16}
        autoplay={{
          delay: 6000,
        }}
        slidesPerView={1}
        centeredSlides
        wrapperClass={styles.wrapper}
        pagination={{ clickable: true, bulletElement: "div" }}
        className={cn("Carousel__blueBullet", styles.carousel)}
      >
        {(testimonials as any[]).map((item: any, i: number) => (
          <SwiperSlide key={i}>
            <div key={i} className="flex flex-col">
              <SliderContent slide1={item[0]} slide2={item[2]} />
              <SliderContent slide2={item[3]} slide1={item[1]} reverse />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export function StudentsTestimonialsGridCarousel({
  studentsDetails,
}: {
  studentsDetails: Testimonial[];
}) {
  const testimonials = useMemo(() => {
    return singleSlideCalculator(studentsDetails).arrayToRender as never;
  }, []);

  return (
    <div className={styles.root}>
      <Swiper
        modules={[Pagination, Autoplay]}
        spaceBetween={16}
        autoplay={{
          delay: 6000,
        }}
        slidesPerView={1}
        centeredSlides
        wrapperClass={styles.wrapper}
        pagination={{ clickable: true, bulletElement: "div" }}
        className={cn("Carousel__blueBullet", styles.carousel)}
      >
        {(testimonials as any[]).map((item: any, i: number) => (
          <SwiperSlide key={i}>
            <div key={i} className="flex flex-col">
              <SingleSliderContent slide1={item[0]} slide2={item[2]} />
              <SingleSliderContent slide2={item[3]} slide1={item[1]} reverse />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
